/* Technologies Icons */
export const amazonDynamodb = '/technologies/amazonDynamodb.svg';
export const amazonRds = '/technologies/amazonRds.svg';
export const amazonS3 = '/technologies/amazonS3.svg';
export const androidStudio1 = '/technologies/androidStudio1.svg';
export const androidStudio2 = '/technologies/androidStudio2.svg';
export const angular = '/technologies/angular.svg';
export const antd = '/technologies/antd.svg';
export const apacheJmeter = '/technologies/apacheJmeter.svg';
export const awsLambda = '/technologies/awsLambda.svg';
export const aws = '/technologies/aws.svg';
export const azure = '/technologies/azure.svg';
export const bigQuery = '/technologies/bigQuery.svg';
export const bitbucket = '/technologies/bitbucket.svg';
export const blazeMeter = '/technologies/blazeMeter.svg';
export const bluePrism = '/technologies/bluePrism.svg';
export const blueStacks = '/technologies/blueStacks.svg';
export const browserStack = '/technologies/browserStack.svg';
export const charles = '/technologies/charles.svg';
export const ciCd = '/technologies/ciCd.svg';
export const confluence = '/technologies/confluence.svg';
export const contentful = '/technologies/contentful.svg';
export const coreData = '/technologies/coreData.svg';
export const devtools = '/technologies/devtools.svg';
export const docker = '/technologies/docker.svg';
export const expressJS = '/technologies/expressJs.svg';
export const firebase = '/technologies/firebase.svg';
export const flutter = '/technologies/flutter.svg';
export const github1 = '/technologies/github1.svg';
export const github2 = '/technologies/github2.svg';
export const gitlab = '/technologies/gitlab.svg';
export const googleCloud = '/technologies/googleCloud.svg';
export const gradle = '/technologies/gradle.svg';
export const html = '/technologies/html.svg';
export const ionic = '/technologies/ionic.svg';
export const javaScript = '/technologies/javaScript.svg';
export const java = '/technologies/java.svg';
export const jenkins = '/technologies/jenkins.svg';
export const jira = '/technologies/jira.svg';
export const keras = '/technologies/keras.svg';
export const kotlin = '/technologies/kotlin.svg';
export const lighthouse = '/technologies/lighthouse.svg';
export const looker = '/technologies/looker.svg';
export const materialUi = '/technologies/materialUi.svg';
export const microsoftOutlook = '/technologies/microsoftOutlook.svg';
export const microsoftTeams = '/technologies/microsoftTeams.svg';
export const mySql = '/technologies/mySql.svg';
export const nestJS = '/technologies/nestJs.svg';
export const nodeJS = '/technologies/nodeJs.svg';
export const nuxtJS = '/technologies/nuxtJs.svg';
export const objectiveC = '/technologies/objectiveC.svg';
export const postgreSQL1 = '/technologies/postgreSql1.svg';
export const postgreSQL = '/technologies/postgreSql.svg';
export const postman = '/technologies/postman.svg';
export const pubnub = '/technologies/pubnub.svg';
export const pwa = '/technologies/pwa.svg';
export const python = '/technologies/python.svg';
export const pytorch = '/technologies/pytorch.svg';
export const react = '/technologies/react.svg';
export const reactNative = '/technologies/reactNative.svg';
export const reactiveSwift = '/technologies/reactiveSwift.svg';
export const redis = '/technologies/redis.svg';
export const reduxSaga = '/technologies/reduxSaga.svg';
export const redux = '/technologies/redux.svg';
export const scala = '/technologies/scala.svg';
export const seleniumWebDriver = '/technologies/seleniumWebDriver.svg';
export const slack = '/technologies/slack.svg';
export const snowflake = '/technologies/snowflake.svg';
export const soapui = '/technologies/soapui.svg';
export const sonarqube = '/technologies/sonarqube.svg';
export const spark = '/technologies/spark.svg';
export const sqlite = '/technologies/sqlite.svg';
export const swagger = '/technologies/swagger.svg';
export const swift = '/technologies/swift.svg';
export const tableau = '/technologies/tableau.svg';
export const tensorFlow = '/technologies/tensorFlow.svg';
export const twilio = '/technologies/twilio.svg';
export const typescript = '/technologies/typescript.svg';
export const uikit = '/technologies/uikit.svg';
export const visualStudio = '/technologies/visualStudio.svg';
export const vuejs = '/technologies/vuejs.svg';
export const webrtc = '/technologies/webrtc.svg';
export const xamarin = '/technologies/xamarin.svg';
export const xcode = '/technologies/xcode.svg';
export const xgboost = '/technologies/xgboost.svg';
export const net = '/technologies/net.svg';

/* Technologies Icons + Title */
export const amazonDynamoDbTitle = '/technologies/amazonDynamoDbTitle.svg';
export const amazonRdsTitle = '/technologies/amazonRdsTitle.svg';
export const amazonS3Title = '/technologies/amazonS3Title.svg';
export const androidStudioTitle1 = '/technologies/androidStudioTitle1.svg';
export const androidStudioTitle2 = '/technologies/androidStudioTitle2.svg';
export const angularTitle = '/technologies/angularTitle.svg';
export const antdTitle = '/technologies/antdTitle.svg';
export const apacheJmeterTitle = '/technologies/apacheJmeterTitle.svg';
export const awsLambdaTitle = '/technologies/awsLambdaTitle.svg';
export const awsTitle = '/technologies/awsTitle.svg';
export const azureTitle = '/technologies/azureTitle.svg';
export const bigQueryTitle = '/technologies/bigQueryTitle.svg';
export const bitbucketTitle = '/technologies/bitbucketTitle.svg';
export const blazeMeterTitle = '/technologies/blazeMeterTitle.svg';
export const bluePrismTitle = '/technologies/bluePrismTitle.svg';
export const blueStacksTitle = '/technologies/blueStacksTitle.svg';
export const browserStackTitle = '/technologies/browserStackTitle.svg';
export const charlesTitle = '/technologies/charlesTitle.svg';
export const ciCdTitle = '/technologies/ciCdTitle.svg';
export const confluenceTitle = '/technologies/confluenceTitle.svg';
export const coreDataTitle = '/technologies/coreDataTitle.svg';
export const devtoolsTitle = '/technologies/devtoolsTitle.svg';
export const dockerTitle = '/technologies/dockerTitle.svg';
export const expressJsTitle = '/technologies/expressJsTitle.svg';
export const firebaseTitle = '/technologies/firebaseTitle.svg';
export const flutterTitle = '/technologies/flutterTitle.svg';
export const github1Title = '/technologies/github1Title.svg';
export const github2Title = '/technologies/github2Title.svg';
export const gitlabTitle = '/technologies/gitlabTitle.svg';
export const googleCloudTitle = '/technologies/googleCloudTitle.svg';
export const gradleTitle = '/technologies/gradleTitle.svg';
export const htmlTitle = '/technologies/htmlTitle.svg';
export const ionicTitle = '/technologies/ionicTitle.svg';
export const javaScriptTitle = '/technologies/javaScriptTitle.svg';
export const javaTitle = '/technologies/javaTitle.svg';
export const jenkinsTitle = '/technologies/jenkinsTitle.svg';
export const jiraTitle = '/technologies/jiraTitle.svg';
export const kerasTitle = '/technologies/kerasTitle.svg';
export const kotlinTitle = '/technologies/kotlinTitle.svg';
export const kubernetesTitle = '/technologies/kubernetesTitle.svg';
export const lighthouseTitle = '/technologies/lighthouseTitle.svg';
export const lookerTitle = '/technologies/lookerTitle.svg';
export const materialUITitle = '/technologies/materialUITitle.svg';
export const mongoDbTitle = '/technologies/mongoDbTitle.svg';
export const microsoftOutlookTitle = '/technologies/microsoftOutlookTitle.svg';
export const microsoftTeamsTitle = '/technologies/microsoftTeamsTitle.svg';
export const mySQLTitle = '/technologies/mySQLTitle.svg';
export const nestJsTitle = '/technologies/nestJsTitle.svg';
export const nodeJsTitle = '/technologies/nodeJsTitle.svg';
export const nuxtJsTitle = '/technologies/nuxtJsTitle.svg';
export const objectiveCTitle = '/technologies/objectiveCTitle.svg';
export const postgreSQLTitle = '/technologies/postgreSQLTitle.svg';
export const postmanTitle = '/technologies/postmanTitle.svg';
export const pubnubTitle = '/technologies/pubnubTitle.svg';
export const pwaTitle = '/technologies/pwaTitle.svg';
export const pythonTitle = '/technologies/pythonTitle.svg';
export const pyTorchTitle = '/technologies/pyTorchTitle.svg';
export const reactTitle = '/technologies/reactTitle.svg';
export const reactiveSwiftTitle = '/technologies/reactiveSwiftTitle.svg';
export const redisTitle = '/technologies/redisTitle.svg';
export const reduxSagaTitle = '/technologies/reduxSagaTitle.svg';
export const reduxTitle = '/technologies/reduxTitle.svg';
export const scalaTitle = '/technologies/scalaTitle.svg';
export const seleniumWebDriverTitle = '/technologies/seleniumWebDriverTitle.svg';
export const slackTitle = '/technologies/slackTitle.svg';
export const snowflakeTitle = '/technologies/snowflakeTitle.svg';
export const soapUiTitle = '/technologies/soapUiTitle.svg';
export const sonarqubeTitle = '/technologies/sonarqubeTitle.svg';
export const sparkTitle = '/technologies/sparkTitle.svg';
export const sqliteTitle = '/technologies/sqliteTitle.svg';
export const stripeTitle = '/technologies/stripeTitle.svg';
export const swaggerTitle = '/technologies/swaggerTitle.svg';
export const swiftTitle = '/technologies/swiftTitle.svg';
export const swiftUiTitle = '/technologies/swiftUiTitle.svg';
export const tableauTitle = '/technologies/tableauTitle.svg';
export const tensorFlowTitle = '/technologies/tensorFlowTitle.svg';
export const twilioTitle = '/technologies/twilioTitle.svg';
export const typescriptTitle = '/technologies/typescriptTitle.svg';
export const uikitTitle = '/technologies/uikitTitle.svg';
export const visualStudioTitle = '/technologies/visualStudioTitle.svg';
export const vueJsTitle = '/technologies/vueJsTitle.svg';
export const webrtcTitle = '/technologies/webrtcTitle.svg';
export const xamarinTitle = '/technologies/xamarinTitle.svg';
export const xcodeTitle = '/technologies/xcodeTitle.svg';
export const xgboostTitle = '/technologies/xgboostTitle.svg';
export const contentfulTitle = '/technologies/contentfulTitle.svg';
export const netTitle = '/technologies/netTitle.svg';
export const phpTitle = '/technologies/phpTitle.svg';
export const rubyTitle = '/technologies/rubyTitle.svg';
export const nextjsTitle = '/technologies/nextjsTitle.svg';
export const djangoTitle = '/technologies/djangoTitle.svg';
export const scikitTitle = '/technologies/scikitTitle.svg';
export const openAITitle = '/technologies/openAITitle.svg';
export const ibmTitle = '/technologies/ibmTitle.svg';

/* Outlined General */
export const arrowDown = '/outlined/arrowDown.svg';
export const activity = '/outlined/activity.svg';
export const iPhone = '/outlined/iPhone.svg';
export const iPad = '/outlined/iPad.svg';
export const alertCircle = '/outlined/alertCircle.svg';
export const announcement = '/outlined/announcement.svg';
export const arrowNarrowUp = '/outlined/arrowNarrowUp.svg';
export const arrowNarrowDown = '/outlined/arrowNarrowDown.svg';
export const arrowLeft = '/outlined/arrowLeft.svg';
export const arrowRight = '/outlined/arrowRight.svg';
export const bell = '/outlined/bell.svg';
export const bookMark = '/outlined/bookMark.svg';
export const check = '/outlined/check.svg';
export const chevronDown = '/outlined/chevronDown.svg';
export const chevronLeft = '/outlined/chevronLeft.svg';
export const chevronRight = '/outlined/chevronRight.svg';
export const chevronUp = '/outlined/chevronUp.svg';
export const copy = '/outlined/copy.svg';
export const code = '/outlined/code.svg';
export const dotsVertical = '/outlined/dotsVertical.svg';
export const download = '/outlined/download.svg';
export const expand01 = '/outlined/expand01.svg';
export const expand02 = '/outlined/expand02.svg';
export const eye = '/outlined/eye.svg';
export const fileShield = '/outlined/fileShield.svg';
export const filterFunnel = '/outlined/filterFunnel.svg';
export const heartHand = '/outlined/heartHand.svg';
export const heart = '/outlined/heart.svg';
export const home = '/outlined/home.svg';
export const linkExternal = '/outlined/linkExternal.svg';
export const link = '/outlined/link.svg';
export const lock = '/outlined/lock.svg';
export const logIn = '/outlined/logIn.svg';
export const mail = '/outlined/mail.svg';
export const menu = '/outlined/menu.svg';
export const messageCircle = '/outlined/messageCircle.svg';
export const messageDotsCircle = '/outlined/messageDotsCircle.svg';
export const minus = '/outlined/minus.svg';
export const phone = '/outlined/phone.svg';
export const plus = '/outlined/plus.svg';
export const refreshCcw = '/outlined/refreshCcw.svg';
export const save = '/outlined/save.svg';
export const searchLg = '/outlined/searchLg.svg';
export const send = '/outlined/send.svg';
export const settings = '/outlined/settings.svg';
export const shareMaterial = '/outlined/shareMaterial.svg';
export const share = '/outlined/share.svg';
export const shieldTick = '/outlined/shieldTick.svg';
export const target01 = '/outlined/target01.svg';
export const target02 = '/outlined/target02.svg';
export const tool = '/outlined/tool.svg';
export const trash = '/outlined/trash.svg';
export const user = '/outlined/user.svg';
export const users = '/outlined/users.svg';
export const xClose = '/outlined/xClose.svg';
export const bookMarkQuote = '/outlined/bookMarkQuote.svg';

/* Outlined Time & Images & Travel */
export const calendar = '/outlined/calendar.svg';
export const camera = '/outlined/camera.svg';
export const clock = '/outlined/clock.svg';
export const globe0 = '/outlined/globe0.svg';
export const globe01 = '/outlined/globe01.svg';
export const hourglass = '/outlined/hourglass.svg';
export const image = '/outlined/image.svg';
export const markerPin = '/outlined/markerPin.svg';
export const rocket = '/outlined/rocket.svg';
export const truck = '/outlined/truck.svg';
export const watchCircle = '/outlined/watchCircle.svg';

/* Outlined Social media */
export const behance = '/outlined/behance.svg';
export const dribbble = '/outlined/dribbble.svg';
export const instagram = '/outlined/instagram.svg';
export const linkedin = '/outlined/linkedin.svg';
export const medium = '/outlined/medium.svg';
export const twitter = '/outlined/twitter.svg';
export const behanceWhite = '/outlined/behanceWhite.svg';
export const dribbbleWhite = '/outlined/dribbbleWhite.svg';
export const instagramWhite = '/outlined/instagramWhite.svg';
export const linkedinWhite = '/outlined/linkedinWhite.svg';
export const mediumWhite = '/outlined/mediumWhite.svg';
export const twitterWhite = '/outlined/twitterWhite.svg';
export const behanceOutlined = '/outlined/behanceOutlined.svg';
export const instagramOutlined = '/outlined/instagramOutlined.svg';
export const linkedInOultined = '/outlined/linkedInOutlined.svg';
export const mediumOutlined = '/outlined/mediumOutlined.svg';
export const twitterOutlined = '/outlined/twitterOutlined.svg';

/* Outlined Finance */
export const bankNote = '/outlined/bankNote.svg';
export const barChart02 = '/outlined/barChart02.svg';
export const barChart = '/outlined/barChart.svg';
export const coinsStacked = '/outlined/coinsStacked.svg';
export const coins = '/outlined/coins.svg';
export const currencyDollarCircle = '/outlined/currencyDollarCircle.svg';
export const lineChartDown = '/outlined/lineChartDown.svg';
export const lineChartUp = '/outlined/lineChartUp.svg';
export const pieChart = '/outlined/pieChart.svg';
export const presentationChart = '/outlined/presentationChart.svg';
export const shoppingBag = '/outlined/shoppingBag.svg';
export const trendUp = '/outlined/trendUp.svg';
export const wallet = '/outlined/wallet.svg';

/* Outlined Education */
export const award = '/outlined/award.svg';
export const beaker = '/outlined/beaker.svg';
export const bookOpen = '/outlined/bookOpen.svg';
export const briefcase = '/outlined/briefcase.svg';
export const certificate = '/outlined/certificate.svg';
export const graduationHat = '/outlined/graduationHat.svg';
export const trophy = '/outlined/trophy.svg';

/* Outlined Development */
export const attachment = '/outlined/attachment.svg';
export const bezierCurve = '/outlined/bezierCurve.svg';
export const bracketsEllipses = '/outlined/bracketsEllipses.svg';
export const browser = '/outlined/browser.svg';
export const cloud = '/outlined/cloud.svg';
export const codeSquare = '/outlined/codeSquare.svg';
export const checkMark = '/outlined/checkMark.svg';
export const crop = '/outlined/crop.svg';
export const database = '/outlined/database.svg';
export const faceRecognation = '/outlined/faceRecognation.svg';
export const fileCheck = '/outlined/fileCheck.svg';
export const filePlus = '/outlined/filePlus.svg';
export const fileSearch = '/outlined/fileSearch.svg';
export const file = '/outlined/file.svg';
export const folder = '/outlined/folder.svg';
export const hand1 = '/outlined/hand1.svg';
export const hand = '/outlined/hand.svg';
export const headphones = '/outlined/headphones.svg';
export const laptop = '/outlined/laptop.svg';
export const layoutAlt = '/outlined/layoutAlt.svg';
export const layout = '/outlined/layout.svg';
export const lightbulb = '/outlined/lightbulb.svg';
export const list = '/outlined/list.svg';
export const maximize = '/outlined/maximize.svg';
export const microphone = '/outlined/microphone.svg';
export const minimize = '/outlined/minimize.svg';
export const monitor01 = '/outlined/monitor01.svg';
export const monitor02 = '/outlined/monitor02.svg';
export const mouse = '/outlined/mouse.svg';
export const packagee = '/outlined/packagee.svg';
export const palette = '/outlined/palette.svg';
export const penTool = '/outlined/penTool.svg';
export const pencil = '/outlined/pencil.svg';
export const phoneDevelopment = '/outlined/phoneDevelopment.svg';
export const playCircle = '/outlined/playCircle.svg';
export const qrCode = '/outlined/qrCode.svg';
export const route = '/outlined/route.svg';
export const rss = '/outlined/rss.svg';
export const scale = '/outlined/scale.svg';
export const scan = '/outlined/scan.svg';
export const server01 = '/outlined/server01.svg';
export const server02 = '/outlined/server02.svg';
export const signal = '/outlined/signal.svg';
export const sliders = '/outlined/sliders.svg';
export const tablet01 = '/outlined/tablet01.svg';
export const transform = '/outlined/transform.svg';
export const videoRecorder = '/outlined/videoRecorder.svg';
export const volumeMax = '/outlined/volumeMax.svg';
export const youtube = '/outlined/youtube.svg';
export const youtubeWhite = '/outlined/youtubeWhite.svg';

/* Filled General */
export const activityFilled = '/filled/activityFilled.svg';
export const announcementFilled = '/filled/announcementFilled.svg';
export const bellFilled = '/filled/bellFilled.svg';
export const checkFilled = '/filled/checkFilled.svg';
export const editFilled = '/filled/editFilled.svg';
export const expand01Filled = '/filled/expand01Filled.svg';
export const expand02Filled = '/filled/expand02Filled.svg';
export const eyeFilled = '/filled/eyeFilled.svg';
export const fileShieldFilled = '/filled/fileShieldFilled.svg';
export const filterFunnelFilled = '/filled/filterFunnelFilled.svg';
export const heartFilled = '/filled/heartFilled.svg';
export const heartHandFilled = '/filled/heartHandFilled.svg';
export const linkFilled = '/filled/linkFilled.svg';
export const lockFilled = '/filled/lockFilled.svg';
export const mailFilled = '/filled/mailFilled.svg';
export const messageCircleFilled = '/filled/messageCircleFilled.svg';
export const messageDotsCircleFilled = '/filled/messageDotsCircleFilled.svg';
export const messageHeartCircleFilled = '/filled/messageHeartCircleFilled.svg';
export const minusFilled = '/filled/minusFilled.svg';
export const phoneFilled = '/filled/phoneFilled.svg';
export const plusFilled = '/filled/plusFilled.svg';
export const refreshCcwFilled = '/filled/refreshCcwFilled.svg';
export const saveFilled = '/filled/saveFilled.svg';
export const searchLgFilled = '/filled/searchLgFilled.svg';
export const settingsFilled = '/filled/settingsFilled.svg';
export const shieldTickFilled = '/filled/shieldTickFilled.svg';
export const target01Filled = '/filled/target01Filled.svg';
export const target02Filled = '/filled/target02Filled.svg';
export const toolFilled = '/filled/toolFilled.svg';
export const userFilled = '/filled/userFilled.svg';
export const usersFilled = '/filled/usersFilled.svg';
export const aiChatFilled = '/filled/aiChatFilled.svg';
export const play = '/outlined/play.svg';
export const pause = '/outlined/pause.svg';

/* Filled Time & Images & Travel */
export const calendarFilled = '/filled/calendarFilled.svg';
export const cameraFilled = '/filled/cameraFilled.svg';
export const clockFilled = '/filled/clockFilled.svg';
export const globe0Filled = '/filled/globe0Filled.svg';
export const globe01Filled = '/filled/globe01Filled.svg';
export const hourglassFilled = '/filled/hourglassFilled.svg';
export const imageFilled = '/filled/imageFilled.svg';
export const markerPinFilled = '/filled/markerPinFilled.svg';
export const rocketFilled = '/filled/rocketFilled.svg';
export const truckFilled = '/filled/truckFilled.svg';
export const watchCircleFilled = '/filled/watchCircleFilled.svg';

/* Filled Social media */
export const behanceFilled = '/filled/behanceFilled.svg';
export const dribbbleFilled = '/filled/dribbbleFilled.svg';
export const instagramFilled = '/filled/InstagramFilled.svg';
export const linkedinFilled = '/filled/linkedinFilled.svg';
export const mediumFilled = '/filled/mediumFilled.svg';
export const twitterFilled = '/filled/twitterFilled.svg';
export const behanceFilledWhite = '/filled/behanceFilledWhite.svg';
export const dribbbleFilledWhite = '/filled/dribbbleFilledWhite.svg';
export const instagramFilledWhite = '/filled/instagramFilledWhite.svg';
export const linkedinFilledWhite = '/filled/linkedinFilledWhite.svg';
export const mediumFilledWhite = '/filled/mediumFilledWhite.svg';
export const twitterFilledWhite = '/filled/twitterFilledWhite.svg';
export const facebookDark = '/filled/facebookDark.svg';
export const facebookWhite = '/outlined/facebookWhite.svg';
export const youtubeFilledWhite = '/filled/youtubeFilledWhite.svg';
export const youtubeSocialFilled = '/filled/youtubeSocialFilled.svg';

/* Filled Finance */
export const bankNoteFilled = '/filled/bankNoteFilled.svg';
export const barChart02Filled = '/filled/barChart02Filled.svg';
export const barChartFilled = '/filled/barChartFilled.svg';
export const coinsFilled = '/filled/coinsFilled.svg';
export const coinsStackedFilled = '/filled/coinsStackedFilled.svg';
export const currencyDollarCircleFilled = '/filled/currencyDollarCircleFilled.svg';
export const lineChartDownFilled = '/filled/lineChartDownFilled.svg';
export const lineChartUpFilled = '/filled/lineChartUpFilled.svg';
export const pieChartFilled = '/filled/pieChartFilled.svg';
export const presentationChartFilled = '/filled/presentationChartFilled.svg';
export const shoppingBagFilled = '/filled/shoppingBagFilled.svg';
export const trendUpFilled = '/filled/trendUpFilled.svg';
export const walletFilled = '/filled/walletFilled.svg';

/* Filled Education */
export const awardFilled = '/filled/awardFilled.svg';
export const beakerFilled = '/filled/beakerFilled.svg';
export const bookOpenFilled = '/filled/bookOpenFilled.svg';
export const briefcaseFilled = '/filled/briefcaseFilled.svg';
export const certificateFilled = '/filled/certificateFilled.svg';
export const graduationHatFilled = '/filled/graduationHatFilled.svg';
export const trophyFilled = '/filled/trophyFilled.svg';

/* Filled Development */
export const attachmentFilled = '/filled/attachmentFilled.svg';
export const bezierCurveFilled = '/filled/bezierCurveFilled.svg';
export const bracketsEllipsesFilled = '/filled/bracketsEllipsesFilled.svg';
export const browserFilled = '/filled/browserFilled.svg';
export const cloudFilled = '/filled/cloudFilled.svg';
export const codeFilled = '/filled/codeFilled.svg';
export const codeSquareFilled = '/filled/codeSquareFilled.svg';
export const cropFilled = '/filled/cropFilled.svg';
export const databaseFilled = '/filled/databaseFilled.svg';
export const faceRecognationFilled = '/filled/faceRecognationFilled.svg';
export const fileCheckFilled = '/filled/fileCheckFilled.svg';
export const fileFilled = '/filled/fileFilled.svg';
export const filePlusFilled = '/filled/filePlusFilled.svg';
export const fileSearchFilled = '/filled/fileSearchFilled.svg';
export const folderFilled = '/filled/folderFilled.svg';
export const handFilled = '/filled/handFilled.svg';
export const headphonesFilled = '/filled/headphonesFilled.svg';
export const laptopFilled = '/filled/laptopFilled.svg';
export const layoutAltFilled = '/filled/layoutAltFilled.svg';
export const layoutFilled = '/filled/layoutFilled.svg';
export const lightbulbFilled = '/filled/lightbulbFilled.svg';
export const listFilled = '/filled/listFilled.svg';
export const maximizeFilled = '/filled/maximizeFilled.svg';
export const microphoneFilled = '/filled/microphoneFilled.svg';
export const monitor01Filled = '/filled/monitor01Filled.svg';
export const monitor02Filled = '/filled/monitor02Filled.svg';
export const mouseFilled = '/filled/mouseFilled.svg';
export const packageFilled = '/filled/packageFilled.svg';
export const penToolFilled = '/filled/penToolFilled.svg';
export const phoneDevelopmentFilled = '/filled/phoneDevelopmentFilled.svg';
export const playCircleFilled = '/filled/playCircleFilled.svg';
export const qrCodeFilled = '/filled/qrCodeFilled.svg';
export const routeFilled = '/filled/routeFilled.svg';
export const scaleFilled = '/filled/scaleFilled.svg';
export const scanFilled = '/filled/scanFilled.svg';
export const server01Filled = '/filled/server01Filled.svg';
export const signalFilled = '/filled/signalFilled.svg';
export const tablet01Filled = '/filled/tablet01Filled.svg';
export const transformFilled = '/filled/transformFilled.svg';
export const videoRecorderFilled = '/filled/videoRecorderFilled.svg';
export const youtubeFilled = '/filled/youtubeFilled.svg';

/* Filed Another */
export const digit1 = '/filled/digit1.svg';
export const digit2 = '/filled/digit2.svg';
export const digit3 = '/filled/digit3.svg';
export const digit4 = '/filled/digit4.svg';
export const digit5 = '/filled/digit5.svg';
export const digit6 = '/filled/digit6.svg';
export const digit7 = '/filled/digit7.svg';
export const digit8 = '/filled/digit8.svg';
export const digit9 = '/filled/digit9.svg';
export const digit10 = '/filled/digit10.svg';
export const digit11 = '/filled/digit11.svg';
export const dwDigit1 = '/filled/dwDigit1.svg';
export const dwDigit2 = '/filled/dwDigit2.svg';
export const dwDigit3 = '/filled/dwDigit3.svg';
export const dwDigit4 = '/filled/dwDigit4.svg';
export const dwDigit5 = '/filled/dwDigit5.svg';
export const dwDigit6 = '/filled/dwDigit6.svg';
export const yellowCheckMark = '/filled/yellowCheckMark.svg';
export const starFilled = '/filled/star.svg';

/* Logo Yellow */
export const blackLogo = '/logo/blackLogo.svg';
export const defaultLogo = '/logo/defaultLogo.svg';
export const whiteLogo = '/logo/whiteLogo.svg';
export const blackYellowText = '/logo/blackYellowText.svg';
export const whiteYellowText = '/logo/whiteYellowText.svg';

/* Other company logos */
export const clutch = '/company/clutch.svg';
export const allianz = '/company/allianz.svg';
export const bionorica = '/company/bionorica.svg';
export const chickFilA = '/company/chickFilA.svg';
export const macdonalds = '/company/macdonalds.svg';
export const subway = '/company/subway.svg';
export const designRush = '/company/designRush.svg';
export const esquire = '/company/esquire.svg';
export const famliciousReview = '/company/famliciousReview.svg';
export const goodFirms = '/company/goodFirms.svg';
export const oppsitesReview = '/company/oppsitesReview.svg';
export const productHunt = '/company/productHunt.svg';
export const separateReview = '/company/separateReview.svg';
export const techCrunch = '/company/techCrunch.svg';
export const theWeek = '/company/theWeek.svg';
export const time = '/company/time.svg';
export const chats = '/illustration/chats.svg';
export const mlDevelopment = '/illustration/mlDevelopment.svg';
export const mobile = '/illustration/mobile.svg';
export const mvpDevelopment = '/illustration/mvpDevelopment.svg';
export const opensenseTitleBorder = '/illustration/opensenseTitleBorder.svg';
export const softDevelopment = '/illustration/softDevelopment.svg';
export const uiUx = '/illustration/ui&ux.svg';
export const webDevelopmentIllustration = '/illustration/webDevelopmentIllustration.svg';
export const appstore = '/illustration/appstore.svg';
export const blackFillAppstore = '/illustration/blackFillAppstore.svg';
export const appleLogo = '/company/apple.svg';
export const netflix = '/company/netflix.svg';
export const msf = '/company/msf.svg';
export const collegeHunks = '/company/college-hunks-hauling.svg';
export const writer = '/company/writer.svg';

/* Processes */
export const processLaunch = '/processes/Launch-1.svg';
